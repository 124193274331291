<template>
    <v-container fluid no-gutters class="my-0 py-0">
        <filters :cup="true" @getScheduleByLeague="getByFilters" @getByFilters="getByFilters" @getCupState="getCupState" />
        <schedule-match-center :matches="matches" />
    </v-container>
</template>

<script>
    import ScheduleMatchCenter from '@/components/ScheduleMatchCenter'
    import Filters from '@/components/GenericFilters'
    import matchService from '@/services/match'
    import momentTimezone from 'moment-timezone'

    export default {
        components: { ScheduleMatchCenter, Filters },
        data() {
            return {
                headers: [
                    {
                        text: 'Pos.',
                        align: 'start',
                        sortable: false,
                        value: 'position'
                    },
                    { text: 'Club', value: 'club' },
                    { text: 'GP', value: 'gp' },
                    { text: 'Won', value: 'won' },
                    { text: 'Lost', value: 'lost' },
                    { text: 'Win(%)', value: 'win_percentage' },
                    { text: 'Pts+', value: 'pts_pos' },
                    { text: 'Pts-', value: 'pts_neg' },
                    { text: '+/-', value: 'pts_diff' },
                    { text: 'Last 5', value: 'last_5' }
                ],
                matches: [],
                filter: {}
            }
        },
        methods: {
            getMatchesDesc() {
                this.matches.sort(function(a, b) {
                    if (momentTimezone(b.matchDatetime).isAfter(momentTimezone(a.matchDatetime))) {
                        return 1
                    } else {
                        return -1
                    }
                })
            },
            // getScheduleByLeague(leagueId) {
            //     this.filter = {}
            //     this.filter.league = `"league":"${leagueId}"`
            //     matchService.getMatches(1, 100, this.filter).then(resp => {
            //         this.matches = resp.data.content
            //         this.getMatchesDesc(this.matches)
            //     })
            // },
            // getCupMatches() {
            //     this.filter = {}
            //     this.filter.league = `"isCup":${true}`
            //     matchService.getMatches(1, 100, this.filter).then(resp => {
            //         this.matches = resp.data.content
            //         this.getMatchesDesc(this.matches)
            //     })
            // },
            // getScheduleByArena(arenaId) {
            //     this.filter = {}
            //     this.filter.arena = `"arena":"${arenaId}"`
            //     matchService.getMatches(1, 100, this.filter).then(resp => {
            //         this.matches = resp.data.content
            //         this.getMatchesDesc(this.matches)
            //     })
            // },
            getCupState(isCup, leagueId, seasonId) {
                let filters = {}
                if (isCup) {
                    filters = { cup: `isCup: ${isCup}` }
                } else {
                    filters = { phase: 'phase: playoffs', league: `league: ${leagueId}`, season: `season: ${seasonId}` }
                }
                matchService.getMatches(1, 100, filters).then(response => {
                    this.matches = response.data.content
                })
            },
            getByFilters(round = null, arenaId = null, isCup = null, leagueId = null, seasonId = null, phase = null) {
                this.filter = {}
                if (round) {
                    this.filter.round = `"round":"${round}"`
                }
                if (arenaId !== null) {
                    this.filter.arena = `"arena":"${arenaId}"`
                }
                if (isCup) {
                    this.filter.league = `"isCup":${isCup}`
                } else if (phase !== null) {
                    this.filter.phase = `"phase":"${phase}"`
                }
                if (leagueId !== null) {
                    this.filter.league = `"league":"${leagueId}"`
                }
                if (seasonId !== null) {
                    this.filter.team = `"season":"${seasonId}"`
                }
                matchService.getMatches(1, 100, this.filter).then(resp => {
                    this.matches = resp.data.content
                    this.getMatchesDesc(this.matches)
                })
            }
        }
    }
</script>

<style scoped>
.custom-date {
    font-weight: 700;
    font-size: 1rem;

    /* border-style: solid; */
    border-radius: 5px;
    background-color: orange;
    color: white;
}
</style>
