<template>
    <v-container fluid no-gutters class="mx-0 px-0">
        <v-row class="mt-1">
            <v-col cols="12" md="12" align="centyer">
                <v-btn class="custom-date custom-orange py-2 rounded-xl" style="text-transform: capitalize;" @click="scrollToElement">
                    <span>Αποτελέσματα</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-1">
            <v-col cols="8" md="2">
                <v-card class="custom-date custom-orange py-2">
                    <span>Επόμενες αγωνιστικές</span>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            v-for="match in getMatchesAsc(matches.filter(match => match.pending))"
            :key="match.id"
            class="py-0 my-0 font-weight-bold secondary-font"
        >
            <v-col cols="12" class="py-1">
                <match-card :match="match" />
            </v-col>
        </v-row>
        <v-row class="mt-1">
            <v-col cols="7" md="2">
                <v-card id="results" class="custom-date custom-orange py-2">
                    <span>Αποτελέσματα</span>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            v-for="match in getMatchesDesc(matches.filter(match => !match.pending))"
            :key="match.id"
            class="py-0 my-0 font-weight-bold secondary-font"
        >
            <v-col cols="12" class="py-1">
                <match-card :match="match" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import MatchCard from '@/components/MatchCard'
    import momentTimezone from 'moment-timezone'

    export default {
        components: { MatchCard },
        props: {
            matches: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
            getMatchesDesc(matches) {
                matches.sort(function(a, b) {
                    if (momentTimezone(b.matchDatetime).isAfter(momentTimezone(a.matchDatetime))) {
                        return 1
                    } else {
                        return -1
                    }
                })
                return matches
            },
            getMatchesAsc(matches) {
                matches.sort(function(a, b) {
                    if (momentTimezone(a.matchDatetime).isAfter(momentTimezone(b.matchDatetime))) {
                        return 1
                    } else {
                        return -1
                    }
                })
                return matches
            },
            scrollToElement() {
                document.getElementById('results').scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        }
    }
</script>

<style scoped>
.custom-date {
    font-weight: 700;
    font-size: 1rem;

    /* border-style: solid; */
    border-radius: 5px;
    background-color: orange;
    color: white;
}
</style>
