import api from '@/utils/api'

export default {
    getArenas(page, size) {
        const range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        const query = '&filter=' + encodeURIComponent('{"active": true }')
        return api.get('arenas' + (page ? range : '') + query)
    },

    getArena(id) {
        return api.get(`arenas/${id}`)
    }
}
